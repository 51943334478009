/* global Handlebars */

(function ($) {
    //register on document ready
    $(function () {
        Handlebars.registerHelper('priorityLabel', function (priority) {
            var prioritymap = { 'Low': '', 'Medium': 'label-info', 'High': 'label-important' };
            return new Handlebars.SafeString('<span class="label ' + prioritymap[priority] + '">' + priority + '</span>');
        });
        Handlebars.registerHelper('hoursInMinutes', function (timeminutes) {
            if (!isNaN(parseInt(timeminutes,10))) {
                return $.zeroPad(Math.floor(timeminutes / 60.0),2);
            }
        });
        Handlebars.registerHelper('centsToDollars', function (cost) {
            if (!isNaN(parseInt(cost,10)))
                return cost / 100.0;
        });
        Handlebars.registerHelper('minutesRemain', function (timeminutes) {
            if (!isNaN(parseInt(timeminutes,10))) {
                return $.zeroPad(timeminutes % 60, 2);
            }
        });
        Handlebars.registerHelper('minutesDisplay', function (minutes) {
            return $.zeroPad(Math.floor(minutes / 60.0),2) + ':' + $.zeroPad((minutes % 60), 2);
        });
        Handlebars.registerHelper('checked', function (value) {
            if (value === true || parseInt(value,10) > 0 || value === 'true') {
                return new Handlebars.SafeString(' checked="checked" ');
            }
        });
        Handlebars.registerHelper('selected', function(option, value) {
            if (option == value) {
                return new Handlebars.SafeString('selected="selected"');
            }
        });
        Handlebars.registerHelper("is", function(itemA, itemB, options) {
            return (itemA == itemB) ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('greater', function(itemA, itemB, options) {
            return (itemA > itemB) ? options.fn(this) : options.inverse(this);
        });
    });
})(jQuery);