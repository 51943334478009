var Workflow = Workflow || {};
Workflow.Views = Workflow.Views || {};


Workflow.Views.Alert = Backbone.View.extend({

    defaults: {
        message: 'Woah! Big scary alert message!',
        type: 'error',
        prepend: true,
        fadeout: true,
        row: true
    },

    initialize: function (options) {
        _.bindAll(this, 'fadeout');
        this.options = _.extend(this.defaults, options);
        this.render();
    },

    //el: '#maincontent',
    template: Handlebars.compile('{{#if row}}<div class="row">{{/if}}<div class="span12"><div class="alert alert-{{type}}">{{message}}</div></div>{{#if row}}</div>{{/if}}'),

    render: function () {
        var me = this;

        this.$el.empty().append(this.template({
            message: this.options.message,
            type: this.options.type,
            row: this.options.row
        }));

        if (this.options.fadeout)
            setTimeout(function () { me.fadeout(me.$el); }, 5000);
    },

    fadeout: function ($element) {
        $element.fadeOut('slow', function () { $element.remove(); });
    }
});
