(function ($) {
    $.validation = {
        run: function (scope) {
            var formValid = true;
            //watermark plugin check
            if ($.watermark) { $.watermark.hideAll(); }

            //any elements with the validation data
            $('[data-validation]', scope).each(function (ix, el) {
                if (!$.validation._validField(this)) {
                    if (formValid) formValid = false;
                }
            });

            //watermark plugin check
            if ($.watermark) { $.watermark.showAll(); }

            return formValid;

        },

        _validField: function (el) {
            var fieldValid = true, $el = $(el), ruleStr = $el.data('validation');
            //check each of the rules and stop on the first wrong one
            $.each(ruleStr.split(','), function (ix, valRule) {
                var rule = valRule.split('=');
                if (rule.length == 1) rule.push('');
                if (typeof ($.validation[rule[0]]) == 'function') {
                    fieldValid = $.validation[rule[0]]($el, rule[1]);
                    if (!fieldValid) {
                        $el.parents('.control-group:first, .inline-stacked:first').addClass('error');
                        return fieldValid; //break out of the loop
                    }
                }
            });

            //if the field is valid clear any invalid messages
            if (fieldValid) {
                $el.parents('.control-group:first, .inline-stacked:first').removeClass('error');
            }

            return fieldValid;
        },

        //VALIDATION FUNCTIONS

        required: function ($el, parameter) {
            return ($el.val() !== '');
        },
        minlength: function ($el, parameter) {
            return $el.val().length >= parameter;
        },
        maxlength: function ($el, parameter) {
            return $el.val().length <= parameter;
        },
        maxval: function ($el, parameter) {
            var val = $el.val();
            return val.length === 0 || parseInt(val,10) <= parameter;
        },
        regex: function ($el, parameter) {
            var regExp = new RegExp(parameter),
                value = $el.val(),
                matches = regExp.exec(value);
            //no value
            if (!value || !value.length) return true;
            return (!(!matches || !matches.length) && matches[0].length === value.length);
        }
    };

})(jQuery);