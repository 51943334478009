//Region manager, borrowed heavily from backbone marionette 
//http://marionettejs.com/
//allows you to specify 'close' destructor to unbind events and eliminate zombies
var Workflow = Workflow || {};

Workflow.Region = function (selector) {
    if (!selector) {
        throw "Must specify selector for region";
    }
    this.el = selector;
};

_.extend(Workflow.Region.prototype, {
    // Displays a backbone view instance inside of the region.
    // Handles calling the `render` method for you.
    // Reads content directly from the `el` attribute.
    show: function (view) {
        this.ensureEl();
        this.close();
        view.render();
        this.open(view);
        this.currentView = view;
        view.trigger('show'); //trigger a show event on the view
    },

    ensureEl: function () {
        if (!this.$el || this.$el.length === 0) {
            this.$el = this.getEl(this.el);
        }
    },

    // Override this method to change how the region finds the DOM element that it manages.
    // Return a jQuery selector object.
    getEl: function (selector) {
        return $(selector);
    },

    // Override this method to change how the new view is appended to the `$el` that the region is managing.
    open: function (view) {
        this.$el.empty().append(view.el);
    },

    // Close the current view, if there is one.
    // If there is no current view, it does nothing and returns immediately.
    close: function () {
        var view = this.currentView;
        if (!view || view.isClosed) {
            return;
        }

        if (view.close) {
            view.close();
        }

        view.remove();

        delete this.currentView;
    },

    // Attach an existing view to the region.
    // This will not call `render` for the new view, and will not replace the current HTML for the `el` of the region.
    attachView: function (view) {
        this.currentView = view;
    },

    // Reset the region by closing any existing view and clearing out the cached `$el`.
    // The next time a view is shown via this region, the region will re-query the DOM for the region's `el`.
    reset: function () {
        this.close();
        delete this.$el;
    },

    clear: function () {
        this.ensureEl();
        this.close();
        this.$el.empty();
    }
});
