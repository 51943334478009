var Workflow = Workflow || {};
Workflow.Views = Workflow.Views || {};


Workflow.Views.Loading = Backbone.View.extend({
    defaults: {
        isrow: false,
        cssClass: ''
    },

    initialize: function () {
        this.options = _.extend(this.defaults, this.options);
        this.render();
    },

    render: function () {
        var cssClass = (this.options.isrow) ? 'row' : 'span12 loading',
            template = (this.options.isrow) ? '<div class="span12 loading"><p>Loading...</p></div>' : '<p>Loading...</p>';

        if (this.options.cssClass.length > 0) {
            cssClass += ' ' + this.options.cssClass;
        }

        this.$el.removeClass().addClass(cssClass).html(template);
    }
});