var Workflow = Workflow || {};

(function ($) {
    $(function () {
        //external links
        $('body').on('click', 'a[rel=external]', function () {
            if ($(this).data('width') && $(this).data('height')) {
                window.open(this.href, 'Workflow', 'width=' + $(this).data('width') + ',height=' + $(this).data('height')); return false;
            } else {
                window.open(this.href); return false;
            }
        });
        if ($.watermark) $('form').submit($.watermark.hideAll);
    });

    $.zeroPad = function(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    $.endDateFromStart = function (startdate) {
            //expects start date as string in 'dd-mm-yyyy', returns in same format
            var dateArray = startdate.split('-'),
                isLeapYear = function (year) {
                    return ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
                },
                getDaysInMonth = function (year, month) {
                    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
                };
            return getDaysInMonth(parseInt(dateArray[2],10),parseInt(dateArray[1],10) - 1) + '-' + dateArray[1] + '-' + dateArray[2];
    };

})(jQuery);

Workflow.Enums = {
    Role : {
        User : 0,
        Manager : 1,
        Admin : 2
    }
};